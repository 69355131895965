import {lazy} from 'react'
import locales from './locales'
import routes from './routes'
import themes from './themes'
import parseLanguages from '../utils/locale'
import grants from './grants'
import Loading from '../components/Loading/Loading'
import {
    defaultUserData,
    isGranted,
    isAnyGranted,
} from '../utils/auth'
import {getDefaultRoutes} from './getDefaultRoutes'
import auth from "firebase/auth";


const config = {
    firebase: {
        prod: {
            initConfig: {
                apiKey: "AIzaSyCOUHaRXeRwWnF97I94LpNkdyV-CqM_xVM",
                authDomain: "scroogebot-app-dev-38e66.firebaseapp.com",
                databaseURL: "https://scroogebot-app-dev-38e66-default-rtdb.firebaseio.com",
                projectId: "scroogebot-app-dev-38e66",
                storageBucket: "scroogebot-app-dev-38e66.appspot.com",
                messagingSenderId: "824128008695",
                appId: "1:824128008695:web:eea2d46512242110647ef2",
                measurementId: "G-ND4MX7E0M3"
            },
            messaging: {
                publicVapidKey:
                    'BIWE8dSuaSXWt3bAv3rD25F_M-xxqOO9HZPCPMMmnsk5cWz3nqK4EnE60dnRa_uHCsMemTLgTzb5EbrvYJRdvJA',
            },
        },
        // devp: {
        //   initConfig: {
        //     apiKey: 'AIzaSyBQAmNJ2DbRyw8PqdmNWlePYtMP0hUcjpY',
        //     authDomain: 'react-most-wanted-3b1b2.firebaseapp.com',
        //     databaseURL: 'https://react-most-wanted-3b1b2.firebaseio.com',
        //     projectId: 'react-most-wanted-3b1b2',
        //     storageBucket: 'react-most-wanted-3b1b2.appspot.com',
        //     messagingSenderId: '258373383650',
        //     appId: '1:258373383650:web:b49ad5dd28da999a',
        //   },
        //   messaging: {
        //     publicVapidKey:
        //       'BEthk1-Qmoh9opZbi1AUZpxANTu6djVRDph4MLpyO2Qk6Dglm1Sa8Yt_pYi4EhYi3Tj-xgLqUktlbNuP_RP6gto',
        //   },
        // // },
        dev: {
            initConfig: {
                apiKey: "AIzaSyCOUHaRXeRwWnF97I94LpNkdyV-CqM_xVM",
                authDomain: "scroogebot-app-dev-38e66.firebaseapp.com",
                databaseURL: "https://scroogebot-app-dev-38e66-default-rtdb.firebaseio.com",
                projectId: "scroogebot-app-dev-38e66",
                storageBucket: "scroogebot-app-dev-38e66.appspot.com",
                messagingSenderId: "824128008695",
                appId: "1:824128008695:web:eea2d46512242110647ef2",
                measurementId: "G-ND4MX7E0M3"
            },
            messaging: {
                publicVapidKey:
                    'BIWE8dSuaSXWt3bAv3rD25F_M-xxqOO9HZPCPMMmnsk5cWz3nqK4EnE60dnRa_uHCsMemTLgTzb5EbrvYJRdvJA',
            },
        },
        firebaseuiProps: {
            signInOptions: [
                'google.com',
                // 'facebook.com',
                // 'twitter.com',
                // 'github.com',
                // 'password',
                // 'phone',
            ],
        },
    },
    googleMaps: {
        apiKey: 'AIzaSyByMSTTLt1Mf_4K1J9necAbw2NPDu2WD7g',
    },
    auth: {
        grants,
        redirectTo: '/dashboard',
        adminRedirectTo: '/dashboard',
        clientRedirectTo: '/account-summary',
        persistKey: 'auth',
        signInURL: '/signin',
        onAuthStateChanged: async (user, auth) => {
            const {getDatabase, ref, onValue, get, update, off} = await import(
                'firebase/database'
                )
            const db = getDatabase()

            try {
                if (user != null) {
                    const grantsSnap = await get(ref(db, `user_grants/${user.uid}`))
                    const userRolesSnap = await get(ref(db, `user_roles/${user.uid}`))
                    const notifcationsDisabledSnap = await get(
                        ref(db, `disable_notifications/${user.uid}`)
                    )

                    const isAdminSnap = await get(ref(db, `admins/${user.uid}`))
                    const userSnap = await get(ref(db, `users/${user.uid}`))

                    if(userSnap.val()){
                        auth.updateAuth({scroogeBotId: userSnap.val().scroogeBotId})
                    }

                    onValue(ref(db, `user_grants/${user.uid}`), (snap) => {
                        auth.updateAuth({grants: snap.val()})
                    })

                    onValue(ref(db, `disable_notifications/${user.uid}`), (snap) => {
                        auth.updateAuth({notificationsDisabled: !!snap.val()})
                    })
                    onValue(ref(db, `admins/${user.uid}`), (snap) => {
                        auth.updateAuth({isAdmin: !!snap.val()})
                    })

                    onValue(ref(db, `users/${user.uid}/scroogeBotId`), (snap) => {
                        auth.updateAuth({isClient: !!snap.val()})
                    })

                    const userToSave = {
                        ...defaultUserData(user),
                        grants: grantsSnap.val(),
                        roles: userRolesSnap.val(),
                        notificationsDisabled: notifcationsDisabledSnap.val(),
                        isAdmin: !!isAdminSnap.val(),
                        isGranted,
                        isAnyGranted,
                    }
                console.log('userToSave ====>', userToSave)
                    auth.updateAuth(userToSave)

                    update(ref(db, `users/${user.uid}`), {
                        displayName: user.displayName,
                        uid: user.uid,
                        photoURL: user.photoURL,
                        providers: user.providerData,
                        emailVerified: user.emailVerified,
                        isAnonymous: user.isAnonymous,
                        notificationsDisabled: notifcationsDisabledSnap.val(),
                    })

                    update(ref(db, `user_chats/${user.uid}/public_chat`), {
                        displayName: 'Public Chat',
                        lastMessage: 'Group chat',
                        path: `group_chat_messages/public_chat`,
                    })

                } else {
                    off(ref(db))

                    auth.setAuth(defaultUserData(user))
                }
            } catch (error) {
                console.warn(error)
            }
        }
    },
    getDefaultRoutes: getDefaultRoutes,
    routes,
    locale: {
        locales,
        persistKey: 'locale',
        defaultLocale: parseLanguages(['en', 'de', 'ru', 'ua'], 'en'),
        onError: (e) => {
            //console.warn(e)

            return
        },
    },
    menu: {
        MenuContent: lazy(() => import('../components/Menu/MenuContent')),
        MenuHeader: lazy(() =>
            import('../components/MenuHeader/MenuHeader')
        ),
    },
    theme: {
        themes,
        defaultThemeID: 'default',
        defaultType: 'dark',
    },
    pages: {
        LandingPage: lazy(() => import('../pages/LandingPage')),
        PageNotFound: lazy(() => import('../pages/PageNotFound')),
    },
    components: {
        Menu: lazy(() => import('../containers/Menu/Menu')),
        Loading,
    },

    containers: {
        LayoutContainer: lazy(() =>
            import('../containers/LayoutContainer/LayoutContainer')
        ),
    },
}

export default config
