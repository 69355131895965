/* eslint-disable react/jsx-key */
import AuthorizedRoute from '../components/AuthorizedRoute'
import AdminRoute from '../components/AdminRoute'
import React, {lazy} from 'react'
import TraderDiaryProvider from "../providers/Scrooge/TraderDiary/Provider";

const About = lazy(() => import('../pages/About'))
const Admin = lazy(() => import('../pages/Demo/Admin'))
const Companies = lazy(() => import('../pages/Demo/Companies'))
const Company = lazy(() => import('../pages/Demo/Companies/Company'))
const Tasks = lazy(() => import('../pages/Demo/Tasks'))
const Task = lazy(() => import('../pages/Demo/Tasks/Task'))
const FirebaseCols = lazy(() => import('../pages/Firebase/Cols'))
const FirebaseDocs = lazy(() => import('../pages/Firebase/Docs'))
const FirebaseLists = lazy(() => import('../pages/Firebase/Lists'))
const FirebaseMessaging = lazy(() => import('../pages/Firebase/Messaging'))
const FirebasePaths = lazy(() => import('../pages/Firebase/Paths'))
const FirebaseStorage = lazy(() => import('../pages/Firebase/Storage'))
const Dashboard = lazy(() => import('../pages/Dashboard'))
const Stats = lazy(() => import('../pages/Stats'))
const AccountSummary = lazy(() => import('../pages/AccountSummary'))
const TradingDiary = lazy(() => import('../pages/TradingDiary'))
const Referrals = lazy(() => import('../pages/Referrals'))
const Referral = lazy(() => import('../pages/Referrals/Referral'))
const OpenDeals = lazy(() => import('../pages/OpenDeals'))
const Transactions = lazy(() => import('../pages/Transactions'))
const AdminTransactions = lazy(() => import('../pages/Admin/Transactions'))
const AlgoTest = lazy(() => import('../pages/AlgoTest'))

const routes = [
    {
        path: '/about',
        exact: true,
        element: <About/>,
    },
    {
        path: '/dashboard',
        exact: true,
        element: (
            <AdminRoute>
                <Dashboard/>
            </AdminRoute>
        ),
    },
    {
        path: '/account-summary',
        exact: true,
        element: (
            <AuthorizedRoute>
                <AccountSummary/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/trading-diary',
        exact: true,
        element: (
            <AuthorizedRoute>
                <TradingDiary/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/open-deals',
        exact: true,
        element: (
            <AuthorizedRoute>
                <OpenDeals/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/referrals',
        exact: true,
        element: (
            <AuthorizedRoute>
                <Referrals/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/referrals/:uid',
        exact: true,
        element: (
            <AuthorizedRoute>
                <Referral />
            </AuthorizedRoute>
        ),
    },
    {
        path: '/referrals/:uid/:tab',
        exact: true,
        element: (
            <AuthorizedRoute>
                <Referral />
            </AuthorizedRoute>
        ),
    },
    {
        path: '/transactions',
        exact: true,
        element: (
            <AuthorizedRoute>
                <Transactions />
            </AuthorizedRoute>
        ),
    },

    {
        path: '/firebase_paths',
        exact: true,
        element: (
            <AuthorizedRoute>
                <FirebasePaths/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/firebase_lists',
        exact: true,
        element: (
            <AuthorizedRoute>
                <FirebaseLists/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/firebase_docs',
        exact: true,
        element: (
            <AuthorizedRoute>
                <FirebaseDocs/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/firebase_cols',
        exact: true,
        element: (
            <AuthorizedRoute>
                <FirebaseCols/>
            </AuthorizedRoute>
        ),
    },
    // {
    //     path: '/admin/users',
    //     exact: true,
    //     element: (
    //         <AuthorizedRoute>
    //             <Admin/>
    //         </AuthorizedRoute>
    //     ),
    // },

    {
        path: '/admin/transactions',
        exact: true,
        element: (
            <AdminRoute>
                <AdminTransactions />
            </AdminRoute>
        ),
    },
    {
        path: '/companies',
        exact: true,
        element: (
            <AuthorizedRoute>
                <Companies/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/companies/:uid',
        exact: true,
        element: (
            <AuthorizedRoute>
                <Company/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/create_company',
        exact: true,
        element: (
            <AuthorizedRoute>
                <Company/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/tasks',
        exact: true,
        element: (
            <AuthorizedRoute>
                <Tasks/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/tasks/:uid',
        exact: true,
        element: (
            <AuthorizedRoute>
                <Task/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/create_task',
        exact: true,
        element: (
            <AuthorizedRoute>
                <Task/>
            </AuthorizedRoute>
        ),
    },

    {
        path: '/firebase_messaging',
        exact: true,
        element: (
            <AuthorizedRoute>
                <FirebaseMessaging/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/firebase_storage',
        exact: true,
        element: (
            <AuthorizedRoute>
                <FirebaseStorage/>
            </AuthorizedRoute>
        ),
    },

    {
        path: '/algo-test',
        exact: true,
        element: (
            <AdminRoute>
                <AlgoTest/>
            </AdminRoute>
        ),
    },
]

export default routes
