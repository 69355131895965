import AuthorizedRoute from '../components/AuthorizedRoute'
import React, { lazy } from 'react'
import UnauthorizedRoute from '../components/UnauthorizedRoute'
import AdminRoute from "../components/AdminRoute";

const SignIn = lazy(() => import('../pages/SignIn/SignIn'))
const MyAccount = lazy(() => import('../pages/MyAccount/MyAccount'))
const Users = lazy(() => import('../pages/Users'))
const User = lazy(() => import('../pages/Users/User'))
const FirebaseUsers = lazy(() => import('../pages/Users-old'))
const FirebaseUser = lazy(() => import('../pages/Users-old/User'))
const Roles = lazy(() => import('../pages/Roles'))
const Role = lazy(() => import('../pages/Roles/Role'))
const Chats = lazy(() => import('../pages/Chats'))
const CreateChat = lazy(() => import('../pages/CreateChat'))
const GroupChat = lazy(() => import('../pages/GroupChat'))
const EditMembers = lazy(() => import('../pages/EditMembers'))
const EditAdmins = lazy(() => import('../pages/EditAdmins'))
const PageNotFound = lazy(() => import('../pages/PageNotFound'))

const getDefaultRoutes = (appConfig) => {
  return [
    {
      path: '/signin',
      exact: true,
      element: (
        <UnauthorizedRoute redirectTo={appConfig?.auth?.redirectTo || '/'}>
          <SignIn />
        </UnauthorizedRoute>
      ),
    },

    {
      path: '/chats',
      exact: true,
      element: (
        <AdminRoute>
          <Chats />
        </AdminRoute>
      ),
    },
    {
      path: '/chats/:uid',
      exact: true,
      element: (
        <AdminRoute>
          <Chats />
        </AdminRoute>
      ),
    },
    {
      path: '/create_chat',
      exact: true,
      element: (
        <AdminRoute>
          <CreateChat />
        </AdminRoute>
      ),
    },
    {
      path: '/edit_members/:uid',
      exact: true,
      element: (
        <AdminRoute>
          <EditMembers />
        </AdminRoute>
      ),
    },
    {
      path: '/edit_admins/:uid',
      exact: true,
      element: (
        <AdminRoute>
          <EditAdmins />
        </AdminRoute>
      ),
    },
    {
      path: '/group_chat',
      exact: true,
      element: (
        <AdminRoute>
          <GroupChat />
        </AdminRoute>
      ),
    },
    {
      path: '/group_chat/:uid',
      exact: true,
      element: (
        <AdminRoute>
          <GroupChat />
        </AdminRoute>
      ),
    },
    {
      path: '/admin/roles',
      exact: true,
      element: (
        <AdminRoute>
          <Roles />
        </AdminRoute>
      ),
    },
    {
      path: '/admin/create_role',
      exact: true,
      element: (
        <AdminRoute>
          <Role />
        </AdminRoute>
      ),
    },
    {
      path: '/admin/roles/:uid',
      exact: true,
      element: (
        <AdminRoute>
          <Role />
        </AdminRoute>
      ),
    },
    {
      path: '/admin/roles/:uid/:tab',
      exact: true,
      element: (
        <AdminRoute>
          <Role />
        </AdminRoute>
      ),
    },
    {
      path: '/my_account',
      exact: true,
      element: (
        <AuthorizedRoute>
          <MyAccount />
        </AuthorizedRoute>
      ),
    },
    {
      path: '/admin/users',
      exact: true,
      element: (
        <AdminRoute>
          <Users />
        </AdminRoute>
      ),
    },
    {
      path: '/users',
      exact: true,
      element: (
          <AdminRoute>
            <FirebaseUsers />
          </AdminRoute>
      ),
    },
    {
      path: '/users/:uid',
      exact: true,
      element: (
          <AdminRoute>
            <FirebaseUser />
          </AdminRoute>
      ),
    },
    {
      path: '/users/:uid/:tab',
      exact: true,
      element: (
          <AdminRoute>
            <FirebaseUser />
          </AdminRoute>
      ),
    },

    {
      path: '/admin/users/:uid',
      exact: true,
      element: (
        <AdminRoute>
          <User />
        </AdminRoute>
      ),
    },
    {
      path: '/admin/users/:uid/:tab',
      exact: true,
      element: (
        <AdminRoute>
          <User />
        </AdminRoute>
      ),
    },
    {
      path: '*',

      element: (
        <AuthorizedRoute>
          <PageNotFound />
        </AuthorizedRoute>
      ),
    },
  ]
}

export { getDefaultRoutes }
