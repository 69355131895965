import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../providers/Auth'
import { useConfig } from '../providers/Config'

function UnauthorizedRoute({ children, redirectTo = '/', ...rest }) {
  const { appConfig } = useConfig()
  const { auth: authConfig } = appConfig || {}
  const { redirectTo: _redirectTo = redirectTo, adminRedirectTo, clientRedirectTo } = authConfig || {}
  const { auth } = useAuth()

  console.log('auth =====>', auth, authConfig)

  if (!auth.isAuthenticated) {
    return children
  } else {
    let rd = null;
    if(auth.isClient){
      rd = clientRedirectTo
    }
    if(auth.isAdmin){
      rd = adminRedirectTo
    }
    if(rd){
      return <Navigate to={rd} replace />
    }else return children

  }
}

export default UnauthorizedRoute

