import red from '@mui/material/colors/red'
import pink from '@mui/material/colors/pink'
import green from '@mui/material/colors/green'
import blue from '@mui/material/colors/blue'
import blueGrey from '@mui/material/colors/blueGrey'
import deepPurple from '@mui/material/colors/deepPurple'
import purple from '@mui/material/colors/deepPurple'
import indigo from '@mui/material/colors/indigo'

const themes = [
    {
        id: 'default',
        // color: '#d1d4dc',
        source: {
            trading: {
                // red: 'rgb(246, 70, 93)',
                // green: 'rgb(14, 203, 129)',



                // red: '#f23645',
                red: '#f33645',
                green: '#22ab94',


                yellow: 'rgb(252, 213, 53)',
                gray: 'rgb(119,113,113)',
            },
            // palette: {
            //   // primary: {
            //   //   // main: '#343434',
            //   //   primary: red,
            //   //   secondary: pink,
            //   //   // dark: 'red',
            //   //   // light: 'yellow',
            //   //   // contrastText: 'pink'
            //   //   // main: '#131722'
            //   // },
            //   // secondary: {
            //   //   // main: '#c62828',
            //   //
            //   //
            //   //   // main: '#343434',
            //   //   // dark: 'red',
            //   //   // light: 'yellow',
            //   //   // contrastText: 'pink'
            //   // },
            //   background: {
            //     // paper: '#171b26',
            //     // default: '#131722',
            //     // paper: '#171b25',
            //     default: '#171b25',
            //     // default: '#131722',
            //     paper: '#131722',
            //     // paper: '#1c2228',
            //   },
            //   error: red,
            // },
            palette: {
                // primary: indigo,
                primary: blueGrey,
                secondary: red,
                error: red,
                // background: indigo,
                background: {
                    // paper: '#171b26',
                    // default: '#131722',
                    // paper: '#171b25',
                    // default: '#171b25',
                            default: '#131722',

                    paper: '#131722',

                    // default: blue[900],
                    // default: '#131722',

                    // paper: '#1c2228',
                },
                text: {
                    primary: "#d1d4dc"
                }
            },
            typography: {
                // In Japanese the characters are usually larger.
                // fontSize: 20,
            },

        },
        // components: {
        //     MuiCard: {
        //         styleOverrides: {
        //             root: ({ ownerState }) => ({
        //                 ...(ownerState.variant === 'contained' &&
        //                     ownerState.color === 'primary' && {
        //                         backgroundColor: '#202020',
        //                         color: '#fff',
        //                     }),
        //             }),
        //         },
        //     },
        // },
    },
    {
        id: 'red',
        color: red[500],
        source: {
            trading: {
                // red: 'rgb(246, 70, 93)',
                red: '#f23645',
                // green: 'rgb(14, 203, 129)',
                green: '#25a79a',
                yellow: 'rgb(252, 213, 53)',
            },
            palette: {
                primary: red,
                secondary: pink,
                error: red,
            },
            typography: {
                // In Japanese the characters are usually larger.
                // fontSize: 12,
            },
        },
    },
    // {
    //     id: 'green',
    //     color: green[500],
    //     source: {
    //         trading: {
    //             red: 'rgb(246, 70, 93)',
    //             green: 'rgb(14, 203, 129)',
    //             yellow: 'rgb(252, 213, 53)',
    //         },
    //         palette: {
    //             primary: green,
    //             secondary: red,
    //             error: red,
    //         },
    //         typography: {
    //             // In Japanese the characters are usually larger.
    //             // fontSize: 14,
    //         },
    //     },
    // },
    {
        id: 'standard',
        color: blue[500],
        source: {
            palette: {
                primary: blue,
                secondary: red,
                error: red,
            },
            trading: {
                // red: 'rgb(246, 70, 93)',
                // green: 'rgb(14, 203, 129)',
                // yellow: 'rgb(252, 213, 53)',


                red: '#f23645',
                green: '#25a79a',
                yellow: 'rgb(252, 213, 53)',

            },





        },
    },
]

export default themes
